<div class="d-flex g-0" style="height: 100vh; overflow-y: hidden">
  <app-sidebar></app-sidebar>
  <main >
    <nav class="navbar navbar-light master-data-header">
      <a class="navbar-brand">Subscription</a>
      <app-header></app-header>
    </nav>

    <div class="container" *ngFor="let subscription of listOfSubscriptions">
      <div class="subscription-container">
        <table class="table">
          <tr>
            <td width="349">
              <img
                src="assets/images/robot-banner.jpg"
                width="320"
                height="464"
              />
            </td>
            <td width="110">&nbsp;</td>
            <td style="padding: 28px">
              <table class="table right-section">
                <tr>
                  <td class="left-text">Model Name:-</td>
                  <td class="text-end right-text">{{ subscription.robots && subscription.robots ? subscription.robots.model_no : '-' }}</td>
                </tr>
                <tr>
                  <td class="left-text">Serial Number:-</td>
                  <td class="text-end right-text">{{ subscription.robots && subscription.robots ? subscription.robots.serial_no : '-' }}</td>
                </tr>
                <tr>
                  <td class="left-text">Subscription Status:-</td>
                  <td class="text-end right-text">{{ subscription.is_subscription_active ? subscription.is_subscription_active : 'false' }}</td>
                </tr>
                <tr>
                  <td class="left-text">Plan Start:-</td>
                  <td class="text-end right-text">{{ subscription.subscription_start_date | date:'dd/MM/yyyy' }}</td>
                </tr>
                <tr *ngIf="!subscription.is_subscription_lifetime">
                  <td class="left-text">Plan End:-</td>
                  <td class="text-end right-text">{{ subscription.subscription_end_date ? (subscription.subscription_end_date | date:'dd/MM/yyyy') : '-' }}
                  </td>
                </tr>
                <tr *ngIf="subscription.is_subscription_lifetime">
                  <td class="left-text">AMC Date:-</td>
                  <td class="text-end right-text">{{ subscription.subscription_amc_date ? (subscription.subscription_amc_date | date:'dd/MM/yyyy') : '-' }}
                  </td>
                </tr>
                <!-- <tr>
                  <td class="left-text">Health:-</td>
                  <td class="text-end right-text">90%</td>
                </tr> -->
                <tr>
                  <td class="left-text">Software Version:-</td>
                  <td class="text-end right-text">{{ subscription.robots && subscription.robots ? subscription.robots.software_version : '-'}}</td>
                </tr>
                <tr>
                  <td class="left-text">Features:-</td>
                  <td class="text-end right-text" style="max-width: 250px;">{{ subscription.feature.length > 1 ? subscription.feature.join(', ') : subscription.feature[0] }}</td>
                </tr>
                <tr>
                  <td class="left-text">Shutdown:-</td>
                  <td class="text-end right-text"><span>ON</span></td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>

    </div>

    <!-- <div style="display: grid;justify-items: center;color: #9E9E9E;margin-top: 25%;">
          <h2>Coming Soon !!</h2>
        </div> -->
  </main>
</div>
