import { Component } from '@angular/core';

import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {
  listOfUsers: any;
  isAddNewUserModal: boolean = false;
  isUserEditMode: boolean = false;
  isUserDelete: boolean = false;
  userDetails: any;
  deleteUserId: string = '';
  assetSerialNoDropdownStates: boolean[] = [];

  constructor(
    private apiService: ApiService,
    private toastService: ToastService
  ){}


  ngOnInit(){
    let params = {};
    this.apiService.postApi('/employee/list', params).subscribe({ next: (response) =>{
      if (response.code == 200) {
        this.listOfUsers = response.data;
      }
    }, error: (err) => {
      this.toastService.errorToast(err.error.message);
    }
    })
  }

  updateUserList = () => {
    this.ngOnInit();
  }

  displayAddNewUserModal = () => {
    this.isAddNewUserModal = !this.isAddNewUserModal;
  }

  hideAddUserModal = (event: boolean) =>  {
    this.isAddNewUserModal = event;
  }

  displayEditUserModal = (userDetails: any) =>{
    this.isUserEditMode = !this.isUserEditMode;
    this.userDetails = userDetails;
  }

  hideUserEditModal = (event:boolean) => {
    this.isUserEditMode = event;
  }

  displayDeleteUserModal = (userId: string) => {
    this.isUserDelete = !this.isUserDelete;
    this.deleteUserId = userId;
  }

  hideUserDeleteModal = (event:boolean) => {
    this.isUserDelete = event;
  }

  toggleFeaturesDropdown(index: number) {
    this.assetSerialNoDropdownStates[index] = !this.assetSerialNoDropdownStates[index];
  }

  closeFeatureDropdown(index: number) {
    this.assetSerialNoDropdownStates[index] = false;
  }

}
