import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm: FormGroup
  hidePassword: boolean = true;

  constructor(
    private fb: FormBuilder,
    private router :  Router,
    private apiService: ApiService, 
    private authService: AuthService,
    private toastService: ToastService
  ) { 
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(32)]]
    })
  }

  ngOnInit() {
    if (this.authService.isLoggedIn() && !this.authService.isTokenExpired()) {
      this.router.navigate(['dashboard']);
      window.history.pushState(null, '');
    }else{
      this.authService.removeToken();
    }
  }

  displayPassword = () => {
    this.hidePassword = !this.hidePassword;
  }

  login = () => {
    if (this.loginForm.valid) {
      let params = this.loginForm.value;
      this.apiService.postApi('/auth/signin', params).subscribe({ next: (response) => {
        if (response.code == 200) {
          const { token, userData } = response.data;
          if(!userData.is_password_updated){
          this.router.navigate(['reset-password/'+ params.email]);
          }else{
          this.toastService.successToast(response.message);
          this.authService.setToken(token);
          this.authService.setUserData('userData',userData);
          this.router.navigate(['dashboard']);
          this.loginForm.reset(); 
          }  
        }
      }, error: (err) => {
        this.toastService.errorToast(err.error.message);
      }
    })
    }
  }
}
