import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/auth/login/login.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { authGuard } from './components/auth/guards/auth.guard';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { UsersComponent } from './components/users/users.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { SupportTicketComponent } from './components/support-ticket/support-ticket.component';
import { RobotCallerComponent } from './components/robot-caller/robot-caller.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'change-password/:id', component: ChangePasswordComponent },
  { path: 'reset-password/:id', component: ResetPasswordComponent },
  { 
    path: '', 
    canActivate: [authGuard],
    children: [
        { path: 'dashboard', component: AnalyticsComponent },
        { path: 'users', component: UsersComponent },
        { path: 'subscription', component: SubscriptionComponent },
        {
          path: 'personalization',
          loadChildren: () => import('./components/personalization/personalization.module').then(m => m.PersonalizationModule),
        }, 
        { path: 'support-ticket', component: SupportTicketComponent },
        { path: 'robot-caller', component: RobotCallerComponent },
        {
          path: 'help-support',
          loadChildren: () => import('./components/help-support/help-support.module').then(m => m.HelpSupportModule),
        }   
    ]
  },
  { path: '**', redirectTo: '' }, // Redirect unknown paths
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
