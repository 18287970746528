<div class="d-flex g-0" style="height: 100%; overflow-y: hidden;">
  <app-sidebar></app-sidebar>
  <main style="width: 100% !important;">
    <nav class="navbar navbar-light master-data-header">
      <a class="navbar-brand">Support & Ticketing</a>
      <app-header></app-header>
    </nav>

    <div class="content">
      <div class="header">
        <div class="left-heading">
        </div>
        <div class="heading-right" (click)="displayCreateTicketModal()">
          <a>+ Create Ticket</a>
        </div>
      </div>
    </div>

    <div class="tableFixHead">
      <table class="customer-table">
        <thead>
          <tr>
            <th>Ticket ID</th>
            <th>Serial Number</th>
            <th>Subject</th>
            <th>Date</th>
            <th>Request Status</th>
            <th>Comment</th>
            <th>Operation</th>
          </tr>
        </thead>
        <tbody *ngFor="let ticket of listOfTickets; let i = index;">
          <tr>
            <td>{{ ticket.ticket_id ? ticket.ticket_id : '-' }}</td>
            <td>{{ ticket.asset_sn ? ticket.asset_sn : '-' }}</td>
            <td class="content-wrap-up">
              {{ ticket.subject ? ticket.subject : '-' }}</td>
            <td>{{ ticket.createdAt | date : 'dd/mm/yyyy'}}</td>
            <td *ngIf="ticket.status == 'Open'" [ngClass]="ticket.status == 'Open' ? 'status-open':''"><i class="fa-solid fa-circle"></i>{{ ticket.status }}</td>
            <td *ngIf="ticket.status == 'In_progress'" [ngClass]="ticket.status == 'In_progress' ? 'status-inprogress':''"><i class="fa-solid fa-circle"></i>{{ ticket.status == 'In_progress' ? 'In Progress' : '_' }}</td>
            <td *ngIf="ticket.status == 'Complete' || ticket.status == 'Closed'" [ngClass]="ticket.status == 'Complete' || ticket.status == 'Closed' ? 'status-complete':''"><i class="fa-solid fa-circle"></i>{{ ticket.status }}</td>
            <td class="content-wrap-up" style="cursor: pointer;" (click)="getChatData(ticket, i)">
              {{ ticket.comment ? ticket.comment : '-' }}</td>
            <td *ngIf="ticket.status == 'Open' || ticket.status == 'In_progress' " class="d-flex gap-3">
              <!-- <i class="fa-regular fa-pen-to-square edit-icon"></i> -->
              <i class="fa-solid fa-trash-can delete-icon" (click)="displayDeleteTicketModal(ticket._id)"></i>
            </td>

            <td *ngIf="ticket.status == 'Complete'">
              <div class="d-flex gap-2">
                <i class="fa-solid fa-check" style="color: rgb(23, 178, 137);cursor: pointer;" title="Agree" (click)="closeTicket(ticket)"></i>
                <i class="fa-regular fa-circle-xmark" style="color: rgb(244, 67, 54);cursor: pointer;" title="Disagree" (click)="displayConfrimDialogModal(ticket._id)"></i>
              </div>
            </td>
            <td *ngIf="ticket.status == 'Closed'"><span style="background-color: rgb(23, 178, 137); color: #F5F5F5;padding: 3px;">Resolved</span></td>
          </tr>

          <tr *ngIf="isDisplayChatDetails && i == indexValue">
            <td colspan="7" style="background-color: #F5F5F5;padding: 16px;">
              <table class="customer-table nested-table">
                <div class="chat-main">
                  <div class="chat-v-scroll" #scrollContainer>
                    <div class="chat-inner" *ngFor="let chat of chatData">

                      <div class="chat-left-main" *ngIf="chat.sender !== userDetails._id">
                        <div class="chat-left">
                          <div class="chat-1">
                            <div>{{chat.content}}</div>
                            <label class="chat-date">{{chat.createdAt | date:'EE HH:mm'}}</label>
                          </div>
                          <div class="chat-user">
                            <span class="chat-user-icon">AD</span>

                          </div>
                        </div>
                      </div>

                      <div class="chat-right" *ngIf="chat.sender == userDetails._id">
                        <div class="chat-left">
                          <div class="chat-1">
                            <div>{{chat.content}}</div>
                            <label class="chat-date">{{ chat.createdAt | date:'EE HH:mm' }}</label>
                          </div>
                          <div class="chat-user">
                            <span class="chat-user-icon">{{ userDetails.name.charAt(0).toUpperCase() }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <form [formGroup]="ticketChatForm" (ngSubmit)="submitChatData(ticket._id)">
                    <div class="chat-input-main" *ngIf="ticket.status != 'Closed'">
                      <button (click)="getLatestChat(ticket._id)">
                        <i class="fa-solid fa-arrow-rotate-right"></i>
                      </button>
                      <input type="text" placeholder="Type Your Message Here........." formControlName="content">
                      <button type="submit" class="btn" [disabled]="ticketChatForm.invalid">SEND</button>
                    </div>
                  </form>
                </div>
              </table>
            </td>
          </tr>       
        </tbody>
      </table>
    </div>

    <!-- <div style="display: grid;justify-items: center;color: #9E9E9E;margin-top: 25%;">
          <h2>Coming Soon !!</h2>
        </div> -->
  </main>
</div>

<app-create-ticket-modal 
[isCreateTicketModal]="isCreateTicketModal"
(hideCreateTicketModal)="hideCreateTicketModal($event)" 
(updateTicketList)="updateTicketList()"
[userDetails]="userDetails">
</app-create-ticket-modal>

<app-delete-ticket-modal 
[isTicketDelete]="isTicketDelete" 
[ticketId]="ticketId"
(hideDeleteTicketModal)="hideDeleteTicketModal($event)" 
(updateTicketList)="updateTicketList()">
</app-delete-ticket-modal>

<app-confrim-dialog-modal
[isConfrimDialogModal]="isConfrimDialogModal"
[ticketId]="ticketId"
(hideConfrimDialogModal)="hideConfrimDialogModal($event)"
(updateTicketList)="updateTicketList()">
</app-confrim-dialog-modal>