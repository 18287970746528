<div class="d-flex g-0" style="height: 100%; overflow-y: hidden;">
    <app-sidebar></app-sidebar>
      <main style="width: 100% !important;">
        <nav class="navbar navbar-light master-data-header">
          <a class="navbar-brand">Analytics</a>        
          <app-header></app-header>
        </nav>
        <div [ngClass]="{ 'blur-background': loader }">
        <app-filter-analytics-data
        [userDetails]="userDetails"
        (filterAnalyticsData)="onFilterAnalyticsData($event)"
        [totalTaskMileage]="totalTaskMileage"
        [totalTaskDuration]="totalTaskDuration"
        [totalOpenTicket]="totalOpenTicket"
        [totalCountData]="totalCountData"
        >
        </app-filter-analytics-data>

        <app-analytics-charts 
        [robotMileageData]="robotMileageData"
        [robotPerformance]="robotPerformance">
          
        </app-analytics-charts>
        </div>

      </main>
  </div>

  <div class="loader-container" *ngIf="loader">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="loading-text">Loading...</div>
  </div> 
