<!-- Modal Popup -->
<div class="modal modal-backdrop" tabindex="-1" [ngStyle]="{'display': isUserDelete ? 'block' : 'none'}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content shadow">
      <div class="modal-header border-bottom-0">
        <h5 class="modal-title border-bottom-0">Delete Data</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <img src="assets/images/cancel.svg">
        </button>
      </div>
      <div class="modal-body" style="padding-top: 0;">
        <div class="login-form d-flex flex-column">
          <div class="modal-body text-center">
            <p>Are you sure, you want to delete the user data?</p>
          </div>
          <div class="text-center" (click)="deleteUser()">
            <button type="submit" class="btn">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>