import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  userDetails: any;
  activatedPath: string = '';

  constructor(
    private authService: AuthService,
    private router: Router
  ){
    this.userDetails = JSON.parse(this.authService.getUserData('userData'));
    this.router.events.subscribe(() => {
      const urlSegments = this.router.url.split('/');
      this.activatedPath = urlSegments[1];
    });
  }

}

