<div class="customer-portal-login">
    <div class="login-container">
      <div class="login-content d-flex">
        <div class="login-left">
          <img src="assets/images/illustration-bg.svg">
        </div>
  
        <div class="login-right">
          <h3 class="admin-heading">Customer Panel</h3>
          <div class="login-form d-flex flex-column align-items-center justify-content-center">
            <div class="login-logo">
              <img src="assets/images/logo.svg">
            </div>
            <form [formGroup]="forgotForm" (ngSubmit)="forgotPassword()">
              <div class="mb-3 position-relative">
                <label for="exampleInputEmail1" class="form-label">Email address</label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  formControlName="email">
                  <div *ngIf="forgotForm.get('email')?.invalid && forgotForm.get('email')?.touched">
                    <div *ngIf="forgotForm.get('email')?.errors?.['required']" id="emailHelp" class="form-text text-danger">Email is required</div>
                    <div *ngIf="forgotForm.get('email')?.errors?.['email']" id="emailHelp" class="form-text text-danger">Invalid email format</div>
                  </div>
              </div>
              
              <button type="submit" class="btn" [disabled]="forgotForm.invalid">Forgot Password</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>