import { CanActivateFn, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';

import { AuthService } from 'src/app/services/auth/auth.service';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot, 
  state: RouterStateSnapshot
) => {
  const router: Router = inject(Router);
  const authService = inject(AuthService);
  const protectedRoutes: string[] = [
    '/dashboard',
    '/users',
    '/subscription',
    '/support-ticket',
    '/faq'
  ];

    if (authService.isLoggedIn() && !authService.isTokenExpired()) {
      return true;
    } else {
      router.navigate(['user']);
      return false;
    }
};
 