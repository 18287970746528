<div class="d-flex g-0" style="height: 100%; overflow-y: hidden;">
    <app-sidebar></app-sidebar>
      <main style="width: 100% !important;">
        <nav class="navbar navbar-light master-data-header">
          <a class="navbar-brand">Robot Caller</a>        
          <app-header></app-header>
        </nav>
        <div>
            <div class="tableFixHead">
                <table class="customer-table">
                  <thead>
                    <tr>
                      <th>Asset Serial Number</th>
                      <th>Model</th>
                      <th>Maps</th>
                      <th>Points</th>
                      <th>Update Map Points</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let asset of listOfRobots">
                    <tr *ngIf="asset.is_robot_coler">
                      <td>{{ asset.serial_no || '-' }}</td>
                      <td>{{ asset.model_no || '-' }}</td>
                      <td>
                        <div class="dropdown">
                          <a class="btn dropdown-toggle" style="width: 150px;" 
                             data-bs-toggle="dropdown" 
                             [class.disabled]="!asset.dropdownEnabled" 
                             [attr.aria-disabled]="!asset.dropdownEnabled ? 'true' : null" 
                             (click)="asset.dropdownEnabled && getAllLocation(asset.serial_no, asset)">
                            {{ asset.selectedMap }}
                          </a>
                          <ul class="dropdown-menu scrollable-dropdown">
                            <li *ngFor="let map of asset.mapOptions">
                              <a class="dropdown-item" (click)="onMapChanges(map._id, asset)">{{ map.map_name }}</a>
                            </li>
                          </ul>
                        </div>
                      </td>
                      <td>
                        <div class="dropdown">
                          <a class="btn dropdown-toggle" style="width: 180px;" 
                             data-bs-toggle="dropdown" 
                             [class.disabled]="!asset.dropdownEnabled || asset.points.length === 0"
                             [attr.aria-disabled]="!asset.dropdownEnabled || asset.points.length === 0 ? 'true' : null">
                            {{ asset.selectedPoint }}
                          </a>
                          <ul class="dropdown-menu scrollable-dropdown">
                            <li *ngFor="let point of asset.points">
                              <a class="dropdown-item" (click)="createOverlays(point, asset)">{{ point.properties.name }}</a>
                            </li>
                          </ul>
                        </div>
                      </td>
                      <td><i class="fa-regular fa-pen-to-square edit-icon" (click)="toggleDropdownEnabled(asset)"></i></td>
                    </tr>
                  </tbody>                
                </table>
              </div> 
        </div>
      </main>
  </div>