import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ClickOutsideDirective } from 'src/app/directives/click-outside.directive';

// Shared Components
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ToastComponent } from './toast/toast.component';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    HeaderComponent,
    SidebarComponent,
    ToastComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideDirective,
    HeaderComponent,  // Export HeaderComponent
    SidebarComponent, // Export SidebarComponent
    ToastComponent
  ]
})
export class SharedModule { }