import { Component } from '@angular/core';

import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent {
  listOfSubscriptions: any;

  constructor(
    private apiService: ApiService,
    private toastService: ToastService
  ){}

  ngOnInit(){
    let params = {};
    this.apiService.postApi('/subscription/by/owner', params).subscribe({ next: (response) =>{
      if (response.code == 200) {
        this.listOfSubscriptions = response.data;
      }
    }, error: (err) => {
      this.toastService.errorToast(err.error.message);
    }
    })
  }

}
