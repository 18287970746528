import { ChartData, ChartOptions } from 'chart.js';

export function generateLineChartData(robotMileageData: any[]): ChartData<'line'> {
  return {
    labels: robotMileageData.map((item: { date: any; }) => item.date),
    datasets: [
      {
        label: 'Robot 1',
        data: robotMileageData.map((item: { mileage: any; }) => item.mileage),
        fill: false,
        tension: 0.3,
        borderColor: '#FF9800',
        backgroundColor: '#FF9800',
        pointBorderColor: '#FF9800',
        pointBackgroundColor: '#FF9800',
      },
      // {
      //   label: 'Robot 2',
      //   data: [28, 48, 40, 19, 86, 27, 90],
      //   fill: false,
      //   tension: 0.3,
      //   borderColor: '#8BC34A',
      //   backgroundColor: '#8BC34A',
      //   pointBorderColor: '#8BC34A',
      //   pointBackgroundColor: '#8BC34A',
      // },
      // {
      //   label: 'Robot 3',
      //   data: [18, 63, 45, 30, 25, 70, 50],
      //   fill: false,
      //   tension: 0.3,
      //   borderColor: '#03A9F4',
      //   backgroundColor: '#03A9F4',
      //   pointBorderColor: '#03A9F4',
      //   pointBackgroundColor: '#03A9F4',
      // }
    ]
  };
}

export function generateLineChartOptions(): ChartOptions<'line'> {
  return {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
        labels: {
          boxWidth: 10
        }
      },
      tooltip: {
        enabled: true,
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Total mileage (meters)'
        },
        grid: {
          display: false
        }
      },
    },
  };
}

export function generateDoughnutChartData(robotPerformance: any[]): ChartData<'doughnut'> {
  return {
    labels: Object.keys(robotPerformance),
    datasets: [
      {
        data: Object.values(robotPerformance),
        backgroundColor: ['#2979FF', '#F06292', '#1DE9B6', '#8BC34A', '#03A9F4' ],
        borderWidth: 1
      }
    ]
  };
}

export function generateDoughnutChartOptions(): ChartOptions<'doughnut'> {
  return {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'start',
        labels: {
          boxWidth: 10
        }
      },
      tooltip: {
        enabled: true
      }
    },
    cutout: '40%'
  };
}
