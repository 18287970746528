<div class="customer-portal-login">
    <div class="login-container">
      <div class="login-content d-flex">
        <div class="login-left">
          <img src="assets/images/illustration-bg.svg">
        </div>
  
        <div class="login-right">
          <h3 class="admin-heading">Customer Panel</h3>
          <div class="login-form d-flex flex-column align-items-center justify-content-center">
            <div class="login-logo">
              <img src="assets/images/logo.svg">
            </div>

            <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">

                <div class="mb-3 position-relative">
                    <label for="exampleInputPassword" class="form-label">Old Password</label>
                    <input type="password" class="form-control login-password" formControlName="oldPassword">
                    <div *ngIf="resetPasswordForm.get('oldPassword')?.invalid && resetPasswordForm.get('oldPassword')?.touched">
                      <div *ngIf="resetPasswordForm.get('oldPassword')?.errors?.['required']" class="form-text text-danger">Old password is required</div>
                    </div>
                </div>

              <div class="mb-3 position-relative">
                <label for="exampleInputPassword1" class="form-label">New Password</label>
                <input [type]="hidePassword ? 'password' : 'text'" class="form-control login-password" id="exampleInputPassword1" formControlName="password">
                <img src="assets/images/password-hide-icon.png" (click)="displayPassword()">
                <div *ngIf="resetPasswordForm.get('password')?.invalid && resetPasswordForm.get('password')?.touched">
                  <div *ngIf="resetPasswordForm.get('password')?.errors?.['required']" class="form-text text-danger">New password is required</div>
                  <div *ngIf="resetPasswordForm.get('password')?.errors?.['pattern']" class="form-text text-danger">Atleast 6 character not more than 32 with alphanumeric & symbol</div>
                </div>
              </div>

              <div class="mb-3 position-relative">
                <label for="exampleInputPassword2" class="form-label">Confrim New Password</label>
                <input type="password" class="form-control login-password" formControlName="confrimPassword">
                <div *ngIf="resetPasswordForm.get('confrimPassword')?.invalid && resetPasswordForm.get('confrimPassword')?.touched">
                <div *ngIf="resetPasswordForm.get('confrimPassword')?.errors?.['required']" class="form-text text-danger">Confrim new password is required</div>
                </div>
                <div class="form-text text-danger" *ngIf="!resetPasswordForm.get('confrimPassword')?.errors?.['required'] && resetPasswordForm.errors?.['PasswordNoMatch']">Password & confrim new password didn't match.</div>
                </div>
                <button type="submit" class="btn" [disabled]="resetPasswordForm.invalid">Reset Password</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>