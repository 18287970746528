import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-confrim-dialog-modal',
  templateUrl: './confrim-dialog-modal.component.html',
  styleUrls: ['./confrim-dialog-modal.component.scss'],
})
export class ConfrimDialogModalComponent {
  @Input() isConfrimDialogModal: boolean = false;
  @Input() ticketId: string = '';
  @Output() hideConfrimDialogModal: EventEmitter<boolean> = new EventEmitter();
  @Output() updateTicketList: EventEmitter<any> = new EventEmitter();
  openReasonForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private toastService: ToastService
  ) {
    this.openReasonForm = this.fb.group({
      open_reason: ['', [Validators.required]],
    });
  }

  closeModal() {
    this.hideConfrimDialogModal.emit(!this.isConfrimDialogModal);
    this.openReasonForm.reset();
  }

  moveTicketIntoProgress() {
    if (this.openReasonForm.valid) {
      let params = {
        id: this.ticketId,
        is_agree: false,
        status: 'In_progress',
        open_reason: this.openReasonForm.value.open_reason,
      };
      this.apiService.postApi('/support/update', params).subscribe({
        next: (response) => {
          if (response.code == 200) {
            this.updateTicketList.emit();
            this.closeModal();
          }
        },
        error: (err) => {
          this.toastService.errorToast(err.error.message);
        },
      });
    }
  }
}
