<!-- Modal Popup -->
<div class="modal modal-backdrop" tabindex="-1" [ngStyle]="{'display': isConfrimDialogModal ? 'block' : 'none'}">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content shadow">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title border-bottom-0">Reason</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <img src="assets/images/cancel.svg">
          </button>
        </div>
        <div class="modal-body" style="padding-top: 0;">
          <div class="login-form d-flex flex-column">
          <form [formGroup]="openReasonForm" (ngSubmit)="moveTicketIntoProgress()">
            <div class="mb-3 position-relative">
              <label class="form-label">Open Reason</label>
              <textarea class="form-control" formControlName="open_reason" style="height: 150px; resize: none;"
                rows="4"></textarea>
              <div *ngIf="openReasonForm.get('open_reason')?.invalid && openReasonForm.get('open_reason')?.touched">
                <div *ngIf="openReasonForm.get('open_reason')?.errors?.['required']" class="form-text text-danger">
                  Open reason is required</div>
              </div>
            </div>

            <div class="text-center">
              <button type="submit" class="btn" [disabled]="openReasonForm.invalid">Submit</button>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
  </div>