import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-delete-ticket-modal',
  templateUrl: './delete-ticket-modal.component.html',
  styleUrls: ['./delete-ticket-modal.component.scss']
})
export class DeleteTicketModalComponent {
  @Input() isTicketDelete: boolean = false; 
  @Input() ticketId: string = '';
  @Output() hideDeleteTicketModal: EventEmitter<boolean> = new EventEmitter();
  @Output() updateTicketList: EventEmitter<any> = new EventEmitter();

  constructor(
    private apiService: ApiService,
    private toastService: ToastService
  ){}

  closeModal = () => {
    this.hideDeleteTicketModal.emit(!this.isTicketDelete);
  }

  deleteTicket = () => {
    let params = { 'id': this.ticketId };
    this.apiService.postApi('/support/delete', params).subscribe({ next: (response) => {
      if (response.code == 200) {
        this.toastService.successToast(response.message);
        this.updateTicketList.emit();
        this.closeModal();        
      }
    }, error: (err) => {
      this.toastService.errorToast(err.error.message);
    }
    })  
  }

}
