import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-delete-user-modal',
  templateUrl: './delete-user-modal.component.html',
  styleUrls: ['./delete-user-modal.component.scss']
})
export class DeleteUserModalComponent {
  @Input() isUserDelete: boolean = false;
  @Input() deleteUserId: string = '';
  @Output() hideUserDeleteModal: EventEmitter<boolean> = new EventEmitter();
  @Output() updateUserList: EventEmitter<any> = new EventEmitter();

  constructor(
    private apiService: ApiService,
    private toastService: ToastService
  ){}

  closeModal = () => {
    this.hideUserDeleteModal.emit(!this.isUserDelete);
  }

  deleteUser = () => {
    let params = { '_id': this.deleteUserId };
    this.apiService.postApi('/employee/delete', params).subscribe({ next: (response) => {
      if (response.code == 200) {
        this.toastService.successToast(response.message);
        this.updateUserList.emit();
        this.closeModal();        
      }
    }, error: (err) => {
      this.toastService.errorToast(err.error.message);
    }
    })  
  }

}
