<div class="container">
  <div class="analytics-container">
    <div class="analytics-search d-flex">
      <div class="dropdown-filter">
        <h4>Robot Overview</h4>
      </div>

      <div class="dropdown-container">
        <div class="filter-dropdown">
          <div class="dropdown">
            <a class="btn dropdown-toggle" style="width: 180px;" data-bs-toggle="dropdown">
              {{ selectedSerialNumber }}
            </a>
            <ul class="dropdown-menu">
              <li *ngFor="let serialNumber of getFilteredSerialNumbers()">
                <a class="dropdown-item" (click)="onFilterChangeAnalytics(serialNumber)">{{ serialNumber }}</a>
              </li>
            </ul>
          </div>
        </div>
      
        <div class="filter-dropdown">
          <div class="dropdown">
            <a class="btn dropdown-toggle" style="width: 158px;" data-bs-toggle="dropdown">{{ selectedValue }}</a>
            <ul class="dropdown-menu">
              <li *ngFor="let option of getFilteredOptions()">
                <a class="dropdown-item" (click)="onFilterAnalyticsChange(option.value)">{{ option.label }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="graph-panel d-flex">
      <div class="graph-data">

        <div class="graph-data-box" style="background: rgba(254, 235, 238, 0.50);">
          <span class="d-inline-block advertisement">
            <img src="assets/images/megaphone.png" style="filter: brightness(0) invert(1);">
          </span>
          <div class="d-flex align-items-end">
            <h2>{{ totalTaskMileage || 0 }}</h2>
            <p style="margin-left: 4px;">KM</p>

          </div>
          <h3>Total Distance</h3>
          <!-- <p>+8% from Yesterday</p> -->
        </div>

        <div class="graph-data-box" style="background:#FFF3E0;">
          <span class="d-inline-block greetings">
            <img src="assets/images/greetings.png" style="filter: brightness(0) invert(1);">
          </span>
          <div class="d-flex align-items-end">
            <h2>{{ totalTaskDuration || 0 }}</h2>
            <p style="margin-left: 4px;">H</p>
          </div>
          <h3>Total Task Duration</h3>
          <!-- <p>+8% from Yesterday</p> -->
        </div>

        <div class="graph-data-box" style="background: rgba(224, 242, 241, 0.50);">
          <span class="d-inline-block open-ticket">
            <img src="assets/images/ticket.png" style="filter: brightness(0) invert(1);">
          </span>
          <div class="d-flex justify-content-between">
            <h2>{{ totalOpenTicket || 0 }}</h2>
          </div>
          <h3>Open Ticket</h3>
          <!-- <p>+8% from Yesterday</p>  -->
        </div>

        <div class="graph-data-box" style="background: rgba(243, 229, 245, 0.50);">
          <span class="d-inline-block total-task">
            <img src="assets/images/total-task.png" style="filter: brightness(0) invert(1);">
          </span>
          <div class="d-flex justify-content-between">
            <h2>{{ totalCountData || 0 }}</h2>
          </div>
          <h3>Total Task</h3>
          <!-- <p>+8% from Yesterday</p> -->
        </div>
      </div>
    </div>
  </div>
</div>