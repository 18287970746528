import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  changePasswordForm: FormGroup;
  hidePassword: boolean = true;
  userEmail: string = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private toastService: ToastService
  ) {
    this.changePasswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,32}')]],
      confrimPassword: ['', [Validators.required]]
    }, { validators: confirmPasswordValidator })
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.userEmail = params['id'];
    });
  }

  displayPassword = () => {
    this.hidePassword = !this.hidePassword;
  }

  changePassword = () => {
    if (this.changePasswordForm.valid) {
      let params = {
        token: this.userEmail,
        password: this.changePasswordForm.value.password
      }
      this.apiService.postApi('/auth/change/password', params).subscribe({ next: (response) =>  {
        if (response.code == 200) {
          this.toastService.successToast(response.message);
          this.changePasswordForm.reset();
          this.router.navigate(['']);
        }
      }, error: (err) => {
        this.toastService.errorToast(err.error.message);
      }
    })
    }
  }
}

  export const confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    return control.value.password === control.value.confrimPassword
      ? null
      : { PasswordNoMatch: true };
  };