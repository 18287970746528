import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss']
})
export class EditUserModalComponent {
  @Input() isUserEditMode: boolean = false;
  @Input() userDetails: any;
  @Output() hideUserEditModal: EventEmitter<boolean> = new EventEmitter();
  @Output() updateUserList: EventEmitter<any> = new EventEmitter();
  listOfAssets: any;
  isAssetsDropdownVisible: boolean = false;
  selectedAssets: string[] = [];
  editUserForm: FormGroup;
  roles = [
    { label: 'Staff', value: 'Staff' },
    { label: 'Waiter', value: 'Waiter' },
    // { label: 'Chef', value: 'Chef'}
  ];

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private toastService: ToastService
  ){
    this.editUserForm = this.fb.group({
      first_name: ['',[Validators.required]],
      last_name: [''],
      user_name: [''],
      email: ['', [Validators.email]],
      phone: ['', [Validators.required,  Validators.pattern(/^(?:\(?\d{3}\)?[\s-]?)?\d{3}[\s-]?\d{4}$/)]],
      emp_code: ['', [Validators.required]],
      role: ['', [Validators.required]],
      robot_id:[[], [Validators.required]],
      pin: ['', [Validators.required]]
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userDetails']?.currentValue) {
      this.editUserForm?.patchValue({
        first_name: this.userDetails.first_name,
        last_name: this.userDetails.last_name,
        user_name: this.userDetails.user_name,
        email: this.userDetails.email,
        phone: this.userDetails.phone,
        emp_code: this.userDetails.emp_code,
        role: this.userDetails.role,
        robot_id:[[], [Validators.required]],
        pin: this.userDetails.pin
      });
      this.selectedAssets = this.userDetails.robot_id;
      this.mapSelectedAssets();
    }
  }

  ngOnInit(){
    let params = {};
    this.apiService.postApi('/robot/list/owner', params).subscribe({ next: (response) =>{
      if (response.code == 200) {
        this.listOfAssets = response.data;
      }
    }, error: (err) => {
      this.toastService.errorToast(err.error.message);
    }
    }) 
  }

  closeModal = () => {
    this.hideUserEditModal.emit(!this.isUserEditMode);
    this.selectedAssets = [];
  }

  toggleFeaturesDropdown() {
    this.isAssetsDropdownVisible = !this.isAssetsDropdownVisible;
  }

  closeFeatureDropdown() {
    this.isAssetsDropdownVisible = false;
  }

  addOrRemoveAssets(optionValue: string, event: any) {
    const isChecked = event.target.checked;
    const index = this.selectedAssets.indexOf(optionValue);
  
    if (isChecked && index === -1) {
      this.selectedAssets.push(optionValue);  // Add if checked and not already present
    } else if (!isChecked && index > -1) {
      this.selectedAssets.splice(index, 1);   // Remove if unchecked and present
    }
  
    this.mapSelectedAssets(); 
  } 

  mapSelectedAssets(){
    this.editUserForm.patchValue({
      robot_id: this.selectedAssets
    });
  }

  updateUserDetails = () => {
    if(this.editUserForm.valid){
      let params = this.editUserForm.value;
      params._id = this.userDetails._id;
      this.apiService.postApi('/employee/update', params).subscribe({ next: (response) =>{
        if (response.code == 200) {
          this.toastService.successToast(response.message);
          this.updateUserList.emit();
          this.closeModal();
        }
      }, error: (err) => {
        this.toastService.errorToast(err.error.message);
      }
      }) 
    }   
  }

}
