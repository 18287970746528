<div class="modal modal-backdrop" tabindex="-1" [ngStyle]="{'display': isCreateTicketModal ? 'block' : 'none'}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content shadow">
      <div class="modal-header border-0">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <img src="assets/images/cancel.svg">
        </button>
      </div>
      <div class="modal-body" style="padding-top: 0;">
        <div class="login-form d-flex flex-column">
          <div class="modal-heading">
            <p>Create Ticket</p>
          </div>
          <form [formGroup]="createTicketForm" (ngSubmit)="createTicket()">
            <div class="mb-3 position-relative">
              <label for="exampleInputEmail1" class="form-label">Subject</label>
              <input type="text" class="form-control" id="name" formControlName="subject">
              <div *ngIf="createTicketForm.get('subject')?.invalid && createTicketForm.get('subject')?.touched">
                <div *ngIf="createTicketForm.get('subject')?.errors?.['required']" class="form-text text-danger">
                  Subject is required</div>
              </div>
            </div>

            <div class="mb-3 position-relative">
              <label for="role" class="form-label">Model</label>
              <select id="role" class="form-control" formControlName="model_no" (change)="getRobotSerialNumber($event)">
                <option value="">Select Model</option>
                <option *ngFor="let asset of listOfAssets" [value]="asset.model_no">
                  {{ asset.model_no }}
                </option>
              </select>
              <div *ngIf="createTicketForm.get('model_no')?.invalid && createTicketForm.get('model_no')?.touched">
                <div *ngIf="createTicketForm.get('model_no')?.errors?.['required']" class="form-text text-danger">Model
                  is required</div>
              </div>
            </div>

            <div class="mb-3 position-relative">
              <label for="role" class="form-label">Serial Number</label>
              <select id="role" class="form-control" formControlName="asset_sn">
                <option value="">Select Serial Number</option>
                <option *ngFor="let asset of listOfSerialNumber" [value]="asset.serial_no">
                  {{ asset.serial_no }}
                </option>
              </select>
              <div *ngIf="createTicketForm.get('asset_sn')?.invalid && createTicketForm.get('asset_sn')?.touched">
                <div *ngIf="createTicketForm.get('asset_sn')?.errors?.['required']" class="form-text text-danger">Serial number
                  is required</div>
              </div>
            </div>

            <div class="mb-3 position-relative">
              <label class="form-label">Description</label>
              <!-- <input type="textarea" class="form-control" formControlName="comment" style="height: 150px !important"> -->
              <textarea class="form-control" formControlName="comment" style="height: 150px; resize: none;"
                rows="4"></textarea>
              <div *ngIf="createTicketForm.get('comment')?.invalid && createTicketForm.get('comment')?.touched">
                <div *ngIf="createTicketForm.get('comment')?.errors?.['required']" class="form-text text-danger">
                  Description is required</div>
              </div>
            </div>

            <div class="text-center">
              <button type="submit" class="btn" [disabled]="createTicketForm.invalid">Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>