import { Component, Input, SimpleChanges } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { generateLineChartData, generateLineChartOptions, generateDoughnutChartData, generateDoughnutChartOptions } from '../chart-utils';

@Component({
  selector: 'app-analytics-charts',
  templateUrl: './analytics-charts.component.html',
  styleUrls: ['./analytics-charts.component.scss']
})
export class AnalyticsChartsComponent {
  @Input() robotMileageData: any[] = [];
  @Input() robotPerformance: any[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['robotMileageData'] && this.robotMileageData || changes['robotPerformance'] && this.robotPerformance) {
      this.updateChartData();
    }
  }

  updateChartData() {
    // Update the line chart data using the utility function
    this.lineChartData = generateLineChartData(this.robotMileageData);
    this.doughnutChartData = generateDoughnutChartData(this.robotPerformance);
  }

  public lineChartType: 'line' = 'line';
  public lineChartData: ChartData<'line'> = generateLineChartData([]);
  public lineChartOptions: ChartOptions<'line'> = generateLineChartOptions();


  public doughnutChartType: 'doughnut' = 'doughnut';
  public doughnutChartData: ChartData<'doughnut'> = generateDoughnutChartData([]);
  public doughnutChartOptions: ChartOptions<'doughnut'> = generateDoughnutChartOptions();
}
