<div class="admin-badge d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
  <span class="rounded-circle avatar d-flex align-items-center justify-content-center">
    <!-- <p style="font-size: 20px;font-weight: 500;text-transform: capitalize;">{{userDetails.username.charAt(0)}}</p> -->
    <i class="fa-regular fa-user"></i>
  </span>
  <span style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 80px;font-weight: 500;text-transform: capitalize;">{{userDetails.name}}</span>
  <ul class="dropdown-menu dropdown-menu-end">
    <li><a class="dropdown-item">{{userDetails.email}}</a></li>
    <li><a class="dropdown-item" (click)="logout()">Logout</a></li>
  </ul>
</div>

  