import { Component } from '@angular/core';

import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  userDetails: any;

  constructor(private authService: AuthService){
    this.userDetails = JSON.parse(this.authService.getUserData('userData'));
  }

  logout(){
    this.authService.removeToken();
  }

}
