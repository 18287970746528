import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgChartsModule } from 'ng2-charts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/auth/login/login.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { UsersComponent } from './components/users/users.component';
import { AddUserModalComponent } from './components/users/add-user-modal/add-user-modal.component';
import { EditUserModalComponent } from './components/users/edit-user-modal/edit-user-modal.component';
import { DeleteUserModalComponent } from './components/users/delete-user-modal/delete-user-modal.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { SupportTicketComponent } from './components/support-ticket/support-ticket.component';
import { CreateTicketModalComponent } from './components/support-ticket/create-ticket-modal/create-ticket-modal.component';
import { DeleteTicketModalComponent } from './components/support-ticket/delete-ticket-modal/delete-ticket-modal.component';
import { AnalyticsChartsComponent } from './components/analytics/analytics-charts/analytics-charts.component';
import { FilterAnalyticsDataComponent } from './components/analytics/filter-analytics-data/filter-analytics-data.component';
import { RobotCallerComponent } from './components/robot-caller/robot-caller.component';
import { ConfrimDialogModalComponent } from './components/support-ticket/confrim-dialog-modal/confrim-dialog-modal.component';
import { SharedModule } from './components/shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    AnalyticsComponent,
    UsersComponent,
    AddUserModalComponent,
    EditUserModalComponent,
    DeleteUserModalComponent,
    SubscriptionComponent,
    SupportTicketComponent,
    CreateTicketModalComponent,
    DeleteTicketModalComponent,
    AnalyticsChartsComponent,
    FilterAnalyticsDataComponent,
    RobotCallerComponent,
    ConfrimDialogModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgChartsModule,
    SharedModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
