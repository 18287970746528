import { Component } from '@angular/core';

import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-robot-caller',
  templateUrl: './robot-caller.component.html',
  styleUrls: ['./robot-caller.component.scss']
})
export class RobotCallerComponent {
  listOfRobots: any[] = [];
  totalMapNames: any[] = [];
  totalPointNames: any[] = [];
  assetSerialNumber: string = '';
  selectedMap: string = 'Map';
  selectedPoint: string = 'Point';
  mapId: string = '';
  selectedMapsPoints: any[] = [];

  constructor(
    private apiService : ApiService,
    private toastService : ToastService,
  ){}

  ngOnInit() {
    this.getAllRobots();
    this.getMapAssignedRobots();
  }
  
  getAllRobots() {
    let params = {};
    this.apiService.postApi('/robot/list/owner', params).subscribe({
      next: (response) => {
        if (response.code === 200) {
          this.listOfRobots = response.data.map((asset: any) => ({
            ...asset,
            selectedMap: 'Map',
            selectedPoint: 'Point',
            mapOptions: [],
            points: [],
            dropdownEnabled: false
          }));
          this.checkMapping();
        }
      },
      error: (err) => {
        this.toastService.errorToast(err.error.message);
      }
    });
  }
  
  getMapAssignedRobots() {
    let params = {};
    this.apiService.postApi('/robot/get/overlase/list', params).subscribe({
      next: (response) => {
        if (response.code === 200) {
          this.selectedMapsPoints = response.data;
          this.checkMapping();
        }
      },
      error: (err) => {
        this.toastService.errorToast(err.error.message);
      }
    });
  }
  
  checkMapping() {
    if (this.listOfRobots && this.selectedMapsPoints) {
      this.listOfRobots = this.listOfRobots.map((robot) => {
        const match = this.selectedMapsPoints.find(
          (point) => point.robot_sn === robot.serial_no
        );
  
        return match
          ? {
              ...robot,
              selectedMap: match.map_id?.map_name || 'Map',
              selectedPoint: match.name || 'Point',
            }
          : robot;
      });
    }
  }
  
  
  toggleDropdownEnabled(asset: any) {
    asset.dropdownEnabled = !asset.dropdownEnabled; 
    if (asset.dropdownEnabled) {
      asset.points = [];
      asset.mapOptions = [];
    }
  }
  
  getAllLocation(assetSerialNumber: string, asset: any) {
    let params = { robot_sn: assetSerialNumber };
  
    this.apiService.postApi('/robot/maps', params).subscribe({
      next: (response) => {
        if (response.code === 200) {
          asset.mapOptions = response.data.map((item: { _id: any; map_name: any; }) => ({
            _id: item._id,
            map_name: item.map_name
          }));
        }
      },
      error: (err) => {
        this.toastService.errorToast(err.error.message);
      }
    });
  }
  
  onMapChanges(mapId: string, asset: any) {
    const selectedMap = asset.mapOptions.find((map: { _id: string; }) => map._id === mapId);
    if (selectedMap) {
      asset.selectedMap = selectedMap.map_name;
    }
    this.mapId = mapId;
  
    let params = { _id: this.mapId };
  
    this.apiService.postApi('/robot/maps/details', params).subscribe({
      next: (response) => {
        if (response.code === 200) {
          asset.points = response.data;
        }
      },
      error: (err) => {
        this.toastService.errorToast(err.error.message);
      }
    });
  }
  
  createOverlays(point: any, asset: any) {
    asset.selectedPoint = point.properties.name;
    let params = {
      map_id: this.mapId,
      name: point.properties.name,
      type: point.type,
      target_x: point.geometry.coordinates[0],
      target_y: point.geometry.coordinates[1],
      target_z: 0,
      target_ori: 1.0,
      robot_sn: asset.serial_no
    };
  
    this.apiService.postApi('/robot/create/overlays', params).subscribe({
      next: (response) => {
        if (response.code === 200) {
          this.toastService.successToast(response.message);
          asset.dropdownEnabled = false;
        }
      },
      error: (err) => {
        this.toastService.errorToast(err.error.message);
      }
    });
  }
  
}
