import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Toast  {
  message: string,
  type: "success" | "error"
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toastSubject = new BehaviorSubject<Toast | null>(null);
  toastState = this.toastSubject.asObservable();

  successToast = (message: string) => {
    this.toastSubject.next({ message, type: 'success' });
    setTimeout(() => this.clearToast(), 3000); 
  }

  errorToast = (message: string) => {
    this.toastSubject.next({ message, type: 'error' });
    setTimeout(() => this.clearToast(), 3000); 
  }

  clearToast = () => {
    this.toastSubject.next(null);
  }

}

