<div class="chart-container">
    <div class="graph-panel d-flex">
        <div class="graph-data">

            <div class="graph-data-box">

                <div class="analytics-search d-flex">
                    <div class="dropdown-filter">
                        <h3>All Robot</h3>
                    </div>
                </div>
                <div style="width: 100%; display: flex;justify-content: center;">
                    <div style="max-height: 260px;">
                        <canvas baseChart
                        height="300"
                        width="600"
                        [data]="lineChartData" 
                        [type]="lineChartType" 
                        [options]="lineChartOptions">
                        </canvas>
                    </div>
                </div>
            </div>

            <div class="graph-data-box">

                <div class="analytics-search d-flex">
                    <div class="dropdown-filter">
                        <h3>Robot Performance</h3>
                    </div>
                </div>

                <div style="width: 100%; display: flex;justify-content: center;">
                    <div style="max-height: 260px;">
                        <canvas baseChart 
                        [data]="doughnutChartData" 
                        [type]="doughnutChartType" 
                        [options]="doughnutChartOptions">
                        </canvas>
                    </div>
                </div>
            
            </div>

        </div>
    </div>

</div>