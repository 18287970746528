<div class="modal modal-backdrop" tabindex="-1" [ngStyle]="{'display': isAddNewUserModal ? 'block' : 'none'}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content shadow">
      <div class="modal-header border-0">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <img src="assets/images/cancel.svg">
        </button>
      </div>
      <div class="modal-body" style="padding-top: 0;">
        <div class="login-form d-flex flex-column">
          <div class="modal-heading">
            <p>Add New User</p>
          </div>

          <form [formGroup]="addNewUserForm" (ngSubmit)="addNewuser()">
            <div class="d-flex">
              <div class="mb-3 position-relative" style="width: 45%;">
                <label class="form-label">First Name</label>
                <input type="text" class="form-control input-box" formControlName="first_name">
                <div *ngIf="addNewUserForm.get('first_name')?.invalid && addNewUserForm.get('first_name')?.touched">
                  <div *ngIf="addNewUserForm.get('first_name')?.errors?.['required']" class="form-text text-danger">
                    First name is required</div>
                </div>
              </div>

              <div class="mb-3 position-relative" style="width: 45%; margin-left: 10%;">
                <label class="form-label">Last Name</label>
                <input type="text" class="form-control input-box" formControlName="last_name">
              </div>
            </div>

            <div class="mb-3 position-relative">
              <label for="exampleInputEmail1" class="form-label">User Name</label>
              <input type="text" class="form-control input-box" id="name" formControlName="user_name">
              <!-- <div *ngIf="addNewUserForm.get('user_name')?.invalid && addNewUserForm.get('user_name')?.touched">
                  <div *ngIf="addNewUserForm.get('user_name')?.errors?.['required']" class="form-text text-danger">
                    User name is required</div>
                </div> -->
            </div>

            <div class="mb-3 position-relative">
              <label for="exampleInputEmail1" class="form-label">Email</label>
              <input type="email" class="form-control input-box" id="name" formControlName="email">
              <div *ngIf="addNewUserForm.get('email')?.invalid && addNewUserForm.get('email')?.touched">
                <!-- <div *ngIf="addNewUserForm.get('email')?.errors?.['required']" class="form-text text-danger">Email is
                  required</div> -->
                <div *ngIf="addNewUserForm.get('email')?.errors?.['email']" class="form-text text-danger">Invalid email
                  format</div>
              </div>
            </div>

            <div class="d-flex">
              <div class="mb-3 position-relative" style="width: 45%;">
                <label class="form-label">Phone No.</label>
                <input type="text" class="form-control input-box" formControlName="phone">
                <div *ngIf="addNewUserForm.get('phone')?.invalid && addNewUserForm.get('phone')?.touched">
                  <div *ngIf="addNewUserForm.get('phone')?.errors?.['required']" class="form-text text-danger">Phone
                    number is required</div>
                  <div *ngIf="addNewUserForm.get('phone')?.errors?.['pattern']" class="form-text text-danger">Invalid
                    phone number</div>
                </div>
              </div>

              <div class="mb-3 position-relative" style="width: 45%; margin-left: 10%;">
                <label class="form-label">Employee ID</label>
                <input type="text" class="form-control input-box" formControlName="emp_code">
                <div *ngIf="addNewUserForm.get('emp_code')?.invalid && addNewUserForm.get('emp_code')?.touched">
                  <div *ngIf="addNewUserForm.get('emp_code')?.errors?.['required']" class="form-text text-danger">
                    Employee id is required</div>
                </div>
              </div>
            </div>

            <div class="mb-3 position-relative">
              <label for="role" class="form-label">Role</label>
              <select id="role" class="form-control" formControlName="role">
                <option value="">Select Role</option>
                <option *ngFor="let role of roles" [value]="role.value">
                  {{ role.label }}
                </option>
              </select>
              <div *ngIf="addNewUserForm.get('role')?.invalid && addNewUserForm.get('role')?.touched">
                <div *ngIf="addNewUserForm.get('role')?.errors?.['required']" class="form-text text-danger">Role is
                  required</div>
              </div>
            </div>

            <div class="mb-3 position-relative">
              <label for="exampleInputEmail1" class="form-label">Asset</label>
              <!-- //(clickOutside)="closeFeatureDropdown()"  [value]="selectedFeatures.join(', ')" -->
              <div class="multiselect-container" (clickOutside)="closeFeatureDropdown()">
                <input class="form-control input-box" (click)="toggleFeaturesDropdown()" placeholder="Multi-Select" />
                <div *ngIf="isAssetsDropdownVisible" class="dropdown">
                  <label *ngFor="let asset of listOfAssets" class="d-flex align-items-center">
                    <input type="checkbox" [value]="asset._id" (change)="addOrRemoveAssets(asset._id, $event)"
                      [checked]="selectedAssets.includes(asset._id)" />
                    <span> {{ asset.model_no }} - {{ asset.serial_no }}</span>
                  </label>
                </div>
              </div>
              <div *ngIf="addNewUserForm.get('robot_id')?.invalid && addNewUserForm.get('robot_id')?.touched">
                <div *ngIf="addNewUserForm.get('robot_id')?.errors?.['required']" class="form-text text-danger">Asset is
                  required</div>
              </div>
            </div>
            <div class="text-center">
              <button type="submit" class="btn" [disabled]="addNewUserForm.invalid">Add Now</button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>