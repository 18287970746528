import { Component } from '@angular/core';

import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent {
  userDetails: any;
  startDateInMilliseconds: number | null = null;
  endDateInMilliseconds: number | null = null;
  totalTaskMileage: number = 0;
  totalTaskDuration: number = 0;
  totalOpenTicket: number = 0;
  totalCountData: number = 0;
  robotMileageData: any[] = [];
  robotPerformance: any[] = [];
  loader: boolean = false;

  constructor(
    private apiService: ApiService,
    private toastService: ToastService,
    private authService: AuthService
  ){
    this.userDetails = JSON.parse(this.authService.getUserData('userData'));
    this.getTodayDates()
  }

  ngOnInit() {
    this.loader = !this.loader;
    const params = {
      startTime: this.startDateInMilliseconds,
      endTime: this.endDateInMilliseconds,
      deviceIds: [this.userDetails.assignRobot[0]]
    };
    this.apiService.postApi('/analytics/list', params).subscribe({
      next: (response) => {
        if (response.code === 200) {    
          this.loader = !this.loader;
          const { mileageData, durationData, openTicket, countData, fullData, statusData } = response.data;
          this.totalTaskMileage = mileageData;
          this.totalTaskDuration = durationData;
          this.totalOpenTicket = openTicket;
          this.totalCountData = countData;
          this.robotMileageData = fullData;
          this.robotPerformance = statusData;
        }
      },
      error: (err) => {
        this.toastService.errorToast(err.error.message);
      }
    });
  }

  getTodayDates() {
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 0);
    const startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - 7);
    startDate.setHours(23, 30, 0, 0);
    this.setFilterDates(startDate, endDate);
  }
  
  setFilterDates(startDate: Date, endDate: Date) {
    this.startDateInMilliseconds = startDate.getTime();
    this.endDateInMilliseconds = endDate.getTime();
  }

  onFilterAnalyticsData(dates: { startDate: Date; endDate: Date, serialNumber: string }) {
    this.setFilterDates(dates.startDate, dates.endDate);
    this.loader = !this.loader;
    const params = {
      startTime: this.startDateInMilliseconds,
      endTime: this.endDateInMilliseconds,
      deviceIds: [dates.serialNumber]
    };
    this.apiService.postApi('/analytics/list', params).subscribe({
      next: (response) => {
        if (response.code === 200) {    
          this.loader = !this.loader;
          this.toastService.successToast(response.message);
          const { mileageData, durationData, openTicket, countData, fullData } = response.data;
          this.totalTaskMileage = mileageData;
          this.totalTaskDuration = durationData;
          this.totalOpenTicket = openTicket;
          this.totalCountData = countData;
          this.robotMileageData = fullData;
        }
      },
      error: (err) => {
        this.toastService.errorToast(err.error.message);
      }
    });
  }
}
