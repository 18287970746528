import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  resetPasswordForm : FormGroup;
  hidePassword : boolean = true;
  userEmail : string = '';
  
  constructor(
    private fb : FormBuilder, 
    private apiService : ApiService, 
    private router :  Router, 
    private activatedRoute : ActivatedRoute,
    private toastService: ToastService
  ){
    this.resetPasswordForm = this.fb.group({
      oldPassword: ['', [Validators.required]],
      password: ['',[Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,32}')]],
      confrimPassword: ['', [Validators.required]]
    }, { validators : confirmPasswordValidator })
  }

  ngOnInit(){
    this.activatedRoute.params.subscribe(params => {
      this.userEmail = params['id'];        
    });
  }

  displayPassword = () => {
    this.hidePassword = !this.hidePassword;
  }

  resetPassword = () => {
    if(this.resetPasswordForm.valid){
      let params = {
        token : this.userEmail,
        oldPassword : this.resetPasswordForm.value.oldPassword,
        password : this.resetPasswordForm.value.password
      }
      this.apiService.postApi('/auth/reset/password', params).subscribe({ next: (response) => {
        if(response.code == 200){
          this.toastService.successToast(response.message);
          this.resetPasswordForm.reset();
          this.router.navigate(['']);
        }
      }, error: (err) => {
        this.toastService.errorToast(err.error.message);
      }
      })
    }
  }
}

  export const confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    return control.value.password === control.value.confrimPassword
      ? null
      : { PasswordNoMatch: true };
  };

