import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-create-ticket-modal',
  templateUrl: './create-ticket-modal.component.html',
  styleUrls: ['./create-ticket-modal.component.scss']
})
export class CreateTicketModalComponent {
  @Input() isCreateTicketModal: boolean = false;
  @Input() userDetails: any;
  @Output() hideCreateTicketModal: EventEmitter<boolean> = new EventEmitter();
  @Output() updateTicketList: EventEmitter<any> = new EventEmitter();
  createTicketForm: FormGroup;
  listOfAssets: any[] = [];
  listOfSerialNumber: any[] = [];

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private toastService: ToastService
  ){
    this.createTicketForm = this.fb.group({
      subject: ['', [Validators.required]],
      model_no: ['', Validators.required],
      asset_sn: ['', [Validators.required]],
      comment: ['', [Validators.required]] 
    })
  }
  
  ngOnInit(){
    let params = { is_support : true };
    this.apiService.postApi('/robot/list/owner', params).subscribe({ next: (response) =>{
      if (response.code == 200) {
        this.listOfAssets = response.data;
      }
    }, error: (err) => {
      this.toastService.errorToast(err.error.message);
    }
    }) 
  }

  getRobotSerialNumber(event: Event){
    const selectedModelNo = (event.target as HTMLSelectElement).value;
    let params = {
      is_sn: true,
      model_no: selectedModelNo
    }
    this.apiService.postApi('/robot/list/owner', params).subscribe({ next: (response) =>{
      if (response.code == 200) {
        this.listOfSerialNumber = response.data;
      }
    }, error: (err) => {
      this.toastService.errorToast(err.error.message);
    }
    }) 
  }

  closeModal = () => {
    this.hideCreateTicketModal.emit(!this.isCreateTicketModal);
    this.createTicketForm.reset();
  }

  createTicket = () => {
    if(this.createTicketForm.valid){
      let { comment, model_no, asset_sn, subject } = this.createTicketForm.value;
      let params = {
        'user_id': this.userDetails._id,
        'comment': comment,
        'model_no': model_no,
        'asset_sn': asset_sn,
        'subject': subject
      };
      this.apiService.postApi('/support/create', params).subscribe({ next: (response) =>{
        if (response.code == 200) {
          this.toastService.successToast(response.message);
          this.updateTicketList.emit();
          this.closeModal();
        }
      }, error: (err) => {
        this.toastService.errorToast(err.error.message);
      }
      }) 
    }
  }

}
