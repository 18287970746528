import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-support-ticket',
  templateUrl: './support-ticket.component.html',
  styleUrls: ['./support-ticket.component.scss']
})
export class SupportTicketComponent {
  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;
  isCreateTicketModal: boolean = false;
  isTicketDelete: boolean = false;
  isConfrimDialogModal: boolean = false;
  listOfTickets: any;
  chatData: any;
  indexValue: number = 0;
  isDisplayChatDetails: boolean = false;
  userDetails: any;
  ticketChatForm: FormGroup;
  ticketId: string = '';

  constructor(
    private apiService: ApiService,
    private toastService: ToastService,
    private authService: AuthService,
    private fb: FormBuilder
  ){
    this.userDetails = JSON.parse(this.authService.getUserData('userData'));
    this.ticketChatForm = this.fb.group({
      content: ['',[Validators.required]]
    })
  }

  ngOnInit(){
    let params = {};
    this.apiService.postApi('/support/list/owner', params).subscribe({ next: (response) =>{
      if (response.code == 200) {
        this.listOfTickets = response.data;
      }
    }, error: (err) => {
      this.toastService.errorToast(err.error.message);
    }
    })
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    if (this.scrollContainer) {
      try {
        this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
      } catch (err) {
        console.error('Error scrolling to bottom:', err);
      }
    }
  }

  updateTicketList = () => {
    this.ngOnInit();
  }

  displayCreateTicketModal = () => {
    this.isCreateTicketModal = !this.isCreateTicketModal;
  }

  hideCreateTicketModal = (event: boolean) => {
    this.isCreateTicketModal = event;
  }

  displayDeleteTicketModal = (deleteId: string) => {
    this.isTicketDelete = !this.isTicketDelete;
    this.ticketId = deleteId;
  }

  hideDeleteTicketModal = (event: boolean) => {
    this.isTicketDelete = event;
  }

  closeTicket(ticket: any){
    let params = {
      id: ticket._id,
      is_agree: !ticket.is_agree,
      status: 'Closed'
    }
    this.apiService.postApi('/support/update', params).subscribe({
      next: (response) => {
        if (response.code == 200) {
        this.updateTicketList();
        }
      }, error: (err) => {
        this.toastService.errorToast(err.error.message);
      }
    })
  }

  displayConfrimDialogModal(ticketId: any){
    this.isConfrimDialogModal = !this.isConfrimDialogModal;
    this.ticketId = ticketId;
  }

  hideConfrimDialogModal = (event: boolean) => {
    this.isConfrimDialogModal = event;
  } 

  getChatData = (ticket: any, index: number) => {
    let params = { 'ticket_id': ticket._id };
    this.apiService.postApi('/support/get/message', params).subscribe({
      next: (response) => {
        if (response.code == 200) {
          this.chatData = response.data;
          if (this.chatData.length > 0) {
            this.indexValue = index;
            this.isDisplayChatDetails = !this.isDisplayChatDetails;
          }else{
            this.startChat(ticket, index);
          }
        }
      }, error: (err) => {
        this.toastService.errorToast(err.error.message);
      }
    })
  }

  startChat = (ticket: any, index: number) => {
    let params = { 'ticket_id': ticket._id, 'content': ticket.comment };
    console.log('parasm',params)
      this.apiService.postApi('/support/create/message', params).subscribe({
        next: (response) => {
          if (response.code == 200) {
            this.indexValue = index;
            this.isDisplayChatDetails = !this.isDisplayChatDetails;
            this.getLatestChat(ticket._id)
          }
        }, error: (err) => {
          this.toastService.errorToast(err.error.message);
        }
      })
  }

  getLatestChat = (ticketId: string) => {
    let params = { 'ticket_id': ticketId };
    this.apiService.postApi('/support/get/message', params).subscribe({
      next: (response) => {
        if (response.code == 200) {
          this.chatData = response.data;
        }
      }, error: (err) => {
        this.toastService.errorToast(err.error.message);
      }
    })
  }

  submitChatData = (ticketId: string) => {
    let params = { 'ticket_id': ticketId, 'content': this.ticketChatForm.value.content };
    if (this.ticketChatForm.valid) {
      this.apiService.postApi('/support/create/message', params).subscribe({
        next: (response) => {
          if (response.code == 200) {
            this.getLatestChat(ticketId);
            this.ticketChatForm.reset();
          }
        }, error: (err) => {
          this.toastService.errorToast(err.error.message);
        }
      })
    }
  }
}
