<nav class="sidebar">
    <div class="left-side-logo text-center">
      <img src="assets/images/logo.svg"/>
    </div>
    <ul class="nav flex-column">
      <li class="nav-item nav-items" [routerLink]="['/dashboard']" [ngClass]="activatedPath === 'dashboard' ? 'active' : ' '">
        <i class="fa-solid fa-chart-line"></i>
          <span>Analytics</span> 
      </li>
      <li class="nav-item" [routerLink]="['/users']" [ngClass]="activatedPath === 'users' ? 'active' : ' '">
        <i class="fa-solid fa-user-group"></i>
          <span>Users</span>
      </li>
      <li class="nav-item" [routerLink]="['/subscription']" [ngClass]="activatedPath === 'subscription' ? 'active' : ' '">
          <i class="fa-regular fa-rectangle-list"></i>
          <span>Subscription</span> 
      </li>
      <li class="nav-item" [routerLink]="['/personalization']" [ngClass]="activatedPath === 'personalization' ? 'active' : ' '">
        <i class="fa-solid fa-hand-holding-heart"></i>
          <span>Personalization</span> 
      </li>
      <li class="nav-item"  [ngClass]="activatedPath === 'templates' ? 'active' : ' '">
          <i class="fa-solid fa-bars"></i>
          <span>Templates</span>
      </li>
      <li class="nav-item" [routerLink]="['/support-ticket']" [ngClass]="activatedPath === 'support-ticket' ? 'active' : ' '">
        <i class="fa-solid fa-ticket"></i>
        <span>Support & Ticketing</span>
       </li>

       <li *ngIf="userDetails.is_robot_coler" class="nav-item" [routerLink]="['/robot-caller']" [ngClass]="activatedPath === 'robot-caller' ? 'active' : ' '">
        <i class="fa-solid fa-robot"></i>
        <span>Robot Caller</span>
       </li>
       
       <li class="nav-item" [ngClass]="activatedPath === 'settings' ? 'active' : ' '">
        <i class="fa-solid fa-gear"></i>
        <span>Settings</span>
      </li>

      <li class="nav-item" [routerLink]="['/help-support']" [ngClass]="activatedPath === 'help-support' ? 'active' : ''">
        <i class="fa-regular fa-circle-question"></i>
        <span>Help & Support</span>
      </li>

    </ul>
  </nav>