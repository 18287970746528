import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent {
  forgotForm: FormGroup;

  constructor(
    private fb: FormBuilder, 
    private apiService: ApiService, 
    private toastService: ToastService
  ) { 
    this.forgotForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  ngOnInit() {}

  forgotPassword = () => {
    if (this.forgotForm.valid) {
      let params = this.forgotForm.value;
      this.apiService.postApi('/auth/forgot/password', params).subscribe({ next: (response) => {
        if (response.code == 200) {
          this.toastService.successToast(response.message);
          this.forgotForm.reset();
        }
      }, error: (err) => {
        this.toastService.errorToast(err.error.message);
      }})
    }
  }

}
