import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private tokenKey = 'authToken';

  constructor(private router: Router) { }

  setUserData = (key: string , value: any): void => {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getUserData = (key: string): any | null => {
    return localStorage.getItem(key);
  }

  setToken = (token: string): void => {
    localStorage.setItem(this.tokenKey, token);
  }

  getToken = (): string | null => {
    return localStorage.getItem(this.tokenKey);
  }

  removeUserData = (key:string) => {
    localStorage.removeItem(key);
  }

  removeUserToken = () => {
    localStorage.removeItem(this.tokenKey);
  }

  isLoggedIn = (): boolean => {
    return !!this.getToken();
  }

  isTokenExpired = (): boolean => {
    const token = localStorage.getItem(this.tokenKey);
    if (token) {
        const decoded: any = jwtDecode(token);
        const exp = decoded.exp * 1000;
        return Date.now() > exp;
    }
    return false;
  }

  removeToken = (): void => {
    this.removeUserData('userData');
    this.removeUserToken();
    this.redirectToLogin();
  }

  redirectToLogin = () => {
    this.router.navigate(['user']);
  }
  
}
