import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.scss']
})
export class AddUserModalComponent {
  @Input() isAddNewUserModal: boolean = false;
  @Output() hideAddUserModal: EventEmitter<boolean> = new EventEmitter();
  @Output() updateUserList: EventEmitter<any> = new EventEmitter();
  listOfAssets: any;
  isAssetsDropdownVisible: boolean = false;
  selectedAssets: string[] = [];
  addNewUserForm: FormGroup;

  roles = [
    { label: 'Staff', value: 'Staff' },
    { label: 'Waiter', value: 'Waiter' },
    // { label: 'Chef', value: 'Chef'}
  ];

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private toastService: ToastService
  ){
    this.addNewUserForm = this.fb.group({
      first_name: ['',[Validators.required]],
      last_name: [''],
      user_name: [''],
      email: ['', [Validators.email]],
      phone: ['', [Validators.required,  Validators.pattern(/^(?:\(?\d{3}\)?[\s-]?)?\d{3}[\s-]?\d{4}$/)]],
      emp_code: ['', [Validators.required]],
      role: ['', [Validators.required]],
      robot_id:[this.selectedAssets, [Validators.required]]
    })
  }

  ngOnInit(){
    let params = {};
    this.apiService.postApi('/robot/list/owner', params).subscribe({ next: (response) =>{
      if (response.code == 200) {
        this.listOfAssets = response.data;
      }
    }, error: (err) => {
      this.toastService.errorToast(err.error.message);
    }
    }) 
  }

  closeModal = () => {
    this.hideAddUserModal.emit(!this.isAddNewUserModal)
    this.selectedAssets = [];
    this.addNewUserForm.reset();
  }

  toggleFeaturesDropdown() {
    this.isAssetsDropdownVisible = !this.isAssetsDropdownVisible;
    this.addNewUserForm.get('robot_id')?.markAsTouched();
  }

  closeFeatureDropdown() {
    this.isAssetsDropdownVisible = false;
  }

  addOrRemoveAssets(optionValue: string, event: any) {
    if (event.target.checked) {
      this.selectedAssets.push(optionValue);
    } else {
      const index = this.selectedAssets.indexOf(optionValue);
      if (index !== -1) {
        this.selectedAssets.splice(index, 1);
      }
    }
    this.mapSelectedAssets(); 
  }

  mapSelectedAssets(){
    this.addNewUserForm.patchValue({
      robot_id: this.selectedAssets
    });
  }

  addNewuser = () => {
    if(this.addNewUserForm.valid){
      let params = this.addNewUserForm.value;
      this.apiService.postApi('/employee/create', params).subscribe({ next: (response) =>{
        if (response.code == 200) {
          this.toastService.successToast(response.message);
          this.updateUserList.emit();
          this.closeModal();
        }
      }, error: (err) => {
        this.toastService.errorToast(err.error.message);
      }
      }) 
    }   
  }

}
