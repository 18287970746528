<div class="customer-portal-login">
  <div class="login-container">
    <div class="login-content d-flex">
      <div class="login-left">
        <img src="assets/images/illustration-bg.svg">
      </div>

      <div class="login-right">
        <h3 class="admin-heading">Customer Panel</h3>
        <div class="login-form d-flex flex-column align-items-center justify-content-center">
          <div class="login-logo">
            <img src="assets/images/logo.svg">
          </div>
          <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="mb-3 position-relative">
              <label for="exampleInputEmail1" class="form-label">Email address</label>
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                formControlName="email">
                <div *ngIf="loginForm.get('email')?.invalid && loginForm.get('email')?.touched">
                  <div *ngIf="loginForm.get('email')?.errors?.['required']" class="form-text text-danger">Email is required</div>
                  <div *ngIf="loginForm.get('email')?.errors?.['email']" class="form-text text-danger">Invalid email format</div>
                </div>
            </div>

            <div class="mb-3 position-relative">
              <label for="exampleInputPassword1" class="form-label">Password</label>
              <input [type]=" hidePassword ? 'password' : 'text'" class="form-control login-password" id="exampleInputPassword1" formControlName="password">
              <img src="assets/images/password-hide-icon.png" (click)="displayPassword()">
              <div *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched">
                <div *ngIf="loginForm.get('password')?.errors?.['required']" class="form-text text-danger">Password is required</div>
                  <div *ngIf="loginForm.get('password')?.errors?.['minlength'] || loginForm.get('password')?.errors?.['maxlength']" class="form-text text-danger">Password can't be less than 6 character & more than 32 character</div>
              </div>
            </div>
            <span class="mb-3 d-block"><a [routerLink]="['/forgot-password']">Forgot Password?</a></span>
            <button type="submit" class="btn" [disabled]="loginForm.invalid">Log In</button>
            <p class="mt-3">By logging In, you’re agreeing to the <a href="">Terms and Conditions.</a></p>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>