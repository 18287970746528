<div class="d-flex g-0" style="height: 100%; overflow-y: hidden;">
    <app-sidebar></app-sidebar>
      <main style="width: 100% !important;">
        <nav class="navbar navbar-light master-data-header">
          <a class="navbar-brand">Users</a>        
          <app-header></app-header>
        </nav>

        <div class="content">
            <div class="header">
                  <div class="left-heading">
                  </div>
                  <div class="heading-right" (click)="displayAddNewUserModal()">
                    <a>+ Add New User</a>
                  </div>
            </div>

            <div class="tableFixHead">
                <table class="customer-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>User Name</th>
                      <th>Email</th>
                      <th>Employee ID</th>
                      <th>Asset</th>
                      <th>Role</th>
                      <th>Opration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let user of listOfUsers; let i = index">
                      <td>{{user.first_name ? user.first_name : '-'}} {{user.last_name ? user.last_name : ''}} </td>
                      <td>{{user.user_name ? user.user_name : '-'}}</td>
                      <td>{{user.email ? user.email : '-'}}</td>
                      <td>{{ user.emp_code ? user.emp_code : '-'}}</td>
                       <td>
                        <div class="multiselect-container" (clickOutside)="closeFeatureDropdown(i)" >
                          <button (click)="toggleFeaturesDropdown(i)" class="feature-label">
                            {{ user && user.robots ? user.robots[0].model_no + ' - ' + user.robots[0].serial_no : '-' }}
                            <ng-container *ngIf="user.robots.length > 1">
                              + {{ user.robots.length - 1 }}
                            </ng-container>
                          </button>
                          <div *ngIf="assetSerialNoDropdownStates[i]" class="dropdown">
                            <label *ngFor="let robot of user.robots">
                              {{ robot.model_no + ' - ' + robot.serial_no }}
                            </label>
                          </div>
                        </div> 
                       </td>
                      <td *ngIf="user.role == 'Staff'"><span [ngClass]="user.role == 'Staff' ? 'role-staff': ''">{{ user.role ? user.role : '-'}}</span></td>
                      <td *ngIf="user.role == 'Waiter'"><span [ngClass]="user.role == 'Waiter' ? 'role-waiter': ''">{{ user.role ? user.role : '-'}}</span></td>
                      <td class="d-flex gap-3">
                        <i class="fa-regular fa-pen-to-square edit-icon" (click)="displayEditUserModal(user)"></i>
                        <i class="fa-solid fa-trash-can delete-icon" (click)="displayDeleteUserModal(user._id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>
      </main>
  </div>

  <app-add-user-modal 
  [isAddNewUserModal]="isAddNewUserModal"
  (hideAddUserModal)="hideAddUserModal($event)"
  (updateUserList)="updateUserList()">
  </app-add-user-modal>

  <app-edit-user-modal
  [isUserEditMode]="isUserEditMode"
  [userDetails]="userDetails"
  (hideUserEditModal)="hideUserEditModal($event)"
  (updateUserList)="updateUserList()">
  </app-edit-user-modal>

  <app-delete-user-modal 
  [isUserDelete]="isUserDelete"
  [deleteUserId]="deleteUserId"
  (hideUserDeleteModal)="hideUserDeleteModal($event)"
  (updateUserList)="updateUserList()">
  </app-delete-user-modal>