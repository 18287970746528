import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment.development'
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  serverUrl = environment.baseUrl;

  constructor( private http : HttpClient, private authService : AuthService ) { }
  
  postApi = (url: string, params: any): Observable<any> => { 
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `${token}`, 
      'Content-Type': 'application/json'
    });
    return this.http.post<any>(this.serverUrl + url, params, {headers})
  }

  uploadFileAsBinary(url: string, params: FormData): Observable<any>{ 
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `${token}`
    });
    return this.http.post<any>(this.serverUrl + url, params, {headers})
  }
}

