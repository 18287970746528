import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-filter-analytics-data',
  templateUrl: './filter-analytics-data.component.html',
  styleUrls: ['./filter-analytics-data.component.scss']
})
export class FilterAnalyticsDataComponent {
  @Input() userDetails: any;
  @Input() totalTaskMileage : number = 0;
  @Input() totalTaskDuration : number = 0;
  @Input() totalOpenTicket : number = 0;
  @Input() totalCountData : number = 0
  @Output() filterAnalyticsData: EventEmitter<{ startDate: Date; endDate: Date; serialNumber: string }> = new EventEmitter()
  selectedValue: string = 'Last 7 Days'; 
  listOfSerialNumber: string[] = [];
  selectedSerialNumber: string = 'Select Robot';

  filterAnalyticsOptions = [
    { label: 'Today', value: 1 },
    { label: 'Last 7 Days', value: 7 },
    { label: 'Last 15 Days', value: 15 },
    { label: 'Last 30 Days', value: 30 }
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userDetails'] && this.userDetails) {
      this.listOfSerialNumber = [...this.userDetails.assignRobot];
      if (this.listOfSerialNumber.length > 0) {
        this.selectedSerialNumber = this.listOfSerialNumber[0];
        this.updateAnalyticsFilters(this.getSelectedValueInDays(), this.selectedSerialNumber);
      }
    }
  }

  getFilteredSerialNumbers(): string[] {
    return this.listOfSerialNumber.filter(serialNumber => serialNumber !== this.selectedSerialNumber);
  }

  getFilteredOptions = () => {
    return this.filterAnalyticsOptions.filter(option => option.label !== this.selectedValue);
  }

  onFilterAnalyticsChange = (value: number): void => {
    const selectedOption = this.filterAnalyticsOptions.find(option => option.value === value);
    if (selectedOption) {
      this.selectedValue = selectedOption.label;
    }
    this.updateAnalyticsFilters(value, this.selectedSerialNumber);
  }

  onFilterChangeAnalytics = (snNo: string) => {
    this.selectedSerialNumber = snNo;
    this.updateAnalyticsFilters(this.getSelectedValueInDays(), snNo);
  }

  getSelectedValueInDays(): number {
    const selectedOption = this.filterAnalyticsOptions.find(option => option.label === this.selectedValue);
    return selectedOption ? selectedOption.value : 7;
  }

  updateAnalyticsFilters(lastDays: number, snNo: string) {
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 0);
    const startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - lastDays);
    startDate.setHours(0, 0, 0, 0);
    this.filterAnalyticsData.emit({ startDate, endDate, serialNumber: snNo });
  }

}
